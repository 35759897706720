import React, { useState, useEffect, useCallback } from 'react';
import TransactionHistory from '../components/TransactionHistory';
import { fetchAllTransactions } from '../api/financialApi';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';

const TransactionHistoryPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());

  const loadTransactions = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { transactions } = await fetchAllTransactions(startDate, endDate);
      setTransactions(transactions);
    } catch (err) {
      console.error('Error loading transactions:', err);
      setError('Failed to load transactions. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);
  
  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <TransactionHistory
      transactions={transactions}
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      onDateChange={loadTransactions}
    />
  );
};

export default TransactionHistoryPage;
import React from 'react';

const CircularProgress = ({ percent, color, children, isOverLimit }) => (
  <div className="relative w-20 h-20 md:w-24 md:h-24"> {/* Larger size for better spacing */}
    <svg className="w-full h-full" viewBox="0 0 36 36" aria-label={`Progress: ${Math.round(percent)}%`}>
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#444"
        strokeWidth="2"
        strokeLinecap="round"
        className="opacity-50"
      />
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke={color}
        strokeWidth="3"
        strokeDasharray={`${percent}, 100`}
        strokeLinecap="round"
      />
    </svg>
    <div
      className={`absolute inset-0 flex items-center justify-center text-sm font-semibold md:text-base ${
        isOverLimit ? 'text-red-500' : 'text-gray-200'
      }`}
    >
      {children}
    </div>
    <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-800 rounded-full px-3 py-1 shadow-md">
      <span className="text-xs font-bold text-gray-300 md:text-sm">
        {Math.round(percent)}%
      </span>
    </div>
  </div>
);


export default CircularProgress;

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ArrowRight, Loader } from 'lucide-react';
import { sendOTP, verifyOTP } from '../api/authApi';

const LoginPage = () => {
  // Use refs to maintain values across re-renders
  const phoneRef = useRef('');
  const stepRef = useRef('phone');
  
  // State for UI updates
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState('phone');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();
  const { login } = useAuth();

  // Initialize component and prevent unwanted page reloads
  useEffect(() => {
    if (phoneRef.current) {
      setPhoneNumber(phoneRef.current);
    }
    if (stepRef.current !== 'phone') {
      setStep(stepRef.current);
    }

    const handleVisibilityChange = (e) => {
      if (document.visibilityState === 'hidden') {
        e.preventDefault();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Keep refs in sync with state
  useEffect(() => {
    phoneRef.current = phoneNumber;
  }, [phoneNumber]);

  useEffect(() => {
    stepRef.current = step;
  }, [step]);

  // Auto-focus OTP input when step changes
  useEffect(() => {
    if (step === 'otp') {
      const otpInput = document.getElementById('otp');
      if (otpInput) {
        setTimeout(() => otpInput.focus(), 100);
      }
    }
  }, [step]);

  const handleSendOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    
    try {
      await sendOTP(phoneNumber);
      setStep('otp');
      stepRef.current = 'otp';
    } catch (err) {
      setError('Falha ao enviar sua senha, por favor tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOTP = async (e) => {
    if (e) e.preventDefault();
    if (otp.length !== 6) return;
    
    setIsLoading(true);
    setError('');

    try {
      const response = await verifyOTP(phoneNumber, otp);
      if (response.isValid && response.token) {
        login(response.token, phoneNumber);
        // Reset refs before navigation
        phoneRef.current = '';
        stepRef.current = 'phone';
        navigate('/dashboard');
      } else {
        setError('Senha incorreta, tente novamente.');
        setOtp(''); // Clear invalid OTP
      }
    } catch (err) {
      setError('Falha ao validar sua senha, tente novamente.');
      setOtp(''); // Clear invalid OTP
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setStep('phone');
    stepRef.current = 'phone';
    setOtp('');
    setError('');
  };

  const LoadingButton = ({ children, ...props }) => (
    <button
      {...props}
      disabled={isLoading}
      className={`w-full p-4 rounded-lg flex items-center justify-center space-x-2 transition-colors
        ${isLoading 
          ? 'bg-blue-800 cursor-not-allowed' 
          : 'bg-blue-600 hover:bg-blue-700'
        } text-white`}
    >
      {isLoading ? (
        <Loader className="w-5 h-5 animate-spin mr-2" />
      ) : null}
      {children}
    </button>
  );

  return (
    <div className="fixed inset-0 bg-gray-900 flex items-center justify-center px-4">
      <div className="bg-gray-800 w-full max-w-md p-8 rounded-lg shadow-xl">
        <div className="flex justify-center mb-8">
          <h2 className="text-3xl font-bold text-blue-400">POQT - Você no controle!</h2>
        </div>
        
        {step === 'phone' ? (
          <form onSubmit={handleSendOTP} className="space-y-4">
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-300 mb-2">
                Telefone (whatsapp)
              </label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber.replace(/^\+55/, '')}
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^\d]/g, '');
                  setPhoneNumber(`+55${inputValue}`);
                }}
                className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-white"
                placeholder="(99) 99999-9999"
                required
                disabled={isLoading}
                autoFocus
              />
            </div>
            <LoadingButton type="submit">
              <span>Enviar senha</span>
              <ArrowRight className="w-5 h-5 ml-2" />
            </LoadingButton>
          </form>
        ) : (
          <form onSubmit={handleVerifyOTP} className="space-y-4">
            <div>
              <label htmlFor="otp" className="block text-sm font-medium text-gray-300 mb-2">
                Digite a senha enviada para o seu Whatsapp
              </label>
              <input
                type="text"
                inputMode="numeric"
                pattern="\d*"
                id="otp"
                value={otp}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  if (value.length <= 6) setOtp(value);
                  // Auto-submit when length is 6
                  if (value.length === 6) {
                    e.target.form.dispatchEvent(new Event('submit', { cancelable: true }));
                  }
                }}
                className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-white text-center text-2xl tracking-widest"
                placeholder="000000"
                maxLength={6}
                required
                disabled={isLoading}
                autoComplete="one-time-code"
              />
              <p className="mt-2 text-sm text-gray-400 text-center">
                Enviado para: {phoneNumber}
              </p>
            </div>
            <LoadingButton type="submit">
              <span>Validar</span>
              <ArrowRight className="w-5 h-5 ml-2" />
            </LoadingButton>
            <button
              type="button"
              onClick={handleBack}
              className="w-full mt-2 text-gray-400 hover:text-white transition-colors"
              disabled={isLoading}
            >
              Voltar para o número de telefone
            </button>
          </form>
        )}

        {error && (
          <div className="mt-4 p-3 bg-red-500 bg-opacity-10 border border-red-500 rounded-lg">
            <p className="text-red-500 text-sm text-center">{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
// src/components/LoadingSpinner.js
import React from 'react';
import { Loader } from 'lucide-react';

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen bg-gray-900">
    <Loader className="w-12 h-12 animate-spin text-blue-500" />
  </div>
);

export default LoadingSpinner;
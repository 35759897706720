import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

// Layouts
import ProtectedLayout from './components/ProtectedLayout';
import LoginLayout from './components/LoginLayout';

// Pages
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import TransactionHistoryPage from './pages/TransactionHistoryPage';
import CategoriesPage from './pages/CategoriesPage';
import Cartoes from './components/Cartoes';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route element={<LoginLayout />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>

          {/* Protected routes */}
          <Route element={<ProtectedLayout />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/transactions" element={<TransactionHistoryPage />} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/cards" element={<Cartoes />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
// src/components/ErrorMessage.js
import React from 'react';
import { AlertTriangle } from 'lucide-react';

const ErrorMessage = ({ message }) => (
  <div className="bg-red-600 text-white p-4 rounded-md mb-4 flex items-center">
    <AlertTriangle className="w-6 h-6 mr-2" />
    <span>{message}</span>
  </div>
);

export default ErrorMessage;

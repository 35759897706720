import React from 'react';
import { formatCurrency } from '../utils/formatters';
import { getCategoryIcon, getHeatmapColorBars } from '../utils/dashboardHelpers';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const BudgetHeatmap = ({ heatmapData = [] }) => {
  if (!Array.isArray(heatmapData) || heatmapData.length === 0) {
    return (
      <section className="bg-gray-800 rounded-lg p-6 mb-6 shadow-lg">
        <h3 className="text-xl font-semibold text-gray-400">
          Não há dados de orçamento disponíveis
        </h3>
      </section>
    );
  }

  return (
    <section className="bg-gray-800 rounded-lg p-6 mb-6 shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold text-gray-200">Gastos por categoria</h3>
        <Link
          to="/categories"
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out flex items-center"
        >
          Ver todas
          <ArrowRight className="ml-2 w-4 h-4" />
        </Link>
      </div>

      <div className="space-y-6">
        {heatmapData.map((item, index) => (
          <div key={index} className="mb-5">
            <div className="flex items-center justify-between mb-2">
              <span className="text-gray-400 mr-2">
                {getCategoryIcon(item.category)}
              </span>
              <span className="flex-grow font-semibold text-gray-300">
                {item.category}
              </span>
              <span className="font-semibold text-gray-300">
                {formatCurrency(Number(item.amount) || 0)}
              </span>
            </div>

            <div className="w-full bg-gray-700 rounded-full h-4 relative">
              <div
                className={`h-4 rounded-full ${getHeatmapColorBars(
                  Number(item.percentUsed) || 0
                )} transition-all duration-500 ease-in-out`}
                style={{
                  width: `${Math.min(Math.max(Number(item.percentUsed) || 0, 0), 100)}%`,
                }}
              >
                <span className="absolute inset-0 flex items-center justify-end pr-3 text-xs font-bold text-white">
                  {Math.round(Number(item.percentUsed) || 0)}%
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BudgetHeatmap;

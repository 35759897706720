import React, { useState, useEffect } from 'react';
import { CreditCard, DollarSign, Calendar, Clock, FileText } from 'lucide-react';
import { formatCurrency } from '../utils/formatters';
import { fetchCreditCards, fetchCreditCardTransactions } from '../api/financialApi';
import LoadingSpinner from './LoadingSpinner';
import ErrorMessage from './ErrorMessage';
import { format, parseISO, setDate, addMonths, subMonths, isAfter, isBefore, startOfDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { getCategoryIcon } from '../utils/dashboardHelpers';

const generateCardStyle = (cardName) => {
  const hashCode = cardName.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);

  // Generate hue, ensuring a full color range (0–359)
  const hue = Math.abs(hashCode % 360);

  // Moderate saturation for contrast
  const saturation = 50; // Keep saturation vivid but not overwhelming.

  // Keep lightness in a contrasting range
  const baseLightness = 50; // Balanced tone for the base color.

  return {
    baseColor: `hsl(${hue}, ${saturation}%, ${baseLightness}%)`, // Main color
    darkColor: `hsl(${hue}, ${saturation}%, ${baseLightness - 15}%)`, // Darker
    lightColor: `hsl(${hue}, ${saturation}%, ${Math.min(baseLightness + 15, 65)}%)` // Lighter but capped at 65% for contrast
  };
};



const parseDate = (dateString) => dateString ? parseISO(dateString) : null;

const formatCardDate = (date) => {
  if (!date) return 'N/A';
  return format(date, "dd 'de' MMMM", { locale: ptBR });
};

const getLastClosingDate = (today, closingDay) => {
  let lastClosingDate = setDate(today, closingDay);
  if (isAfter(lastClosingDate, today)) {
    lastClosingDate = subMonths(lastClosingDate, 1);
  }
  return startOfDay(lastClosingDate);
};

const InfoItem = ({ icon: Icon, label, value }) => (
  <div>
    <p className="text-xs text-white opacity-75 flex items-center">
      <Icon className="w-3 h-3 mr-1" /> {label}
    </p>
    <p className="text-base font-bold text-white">{value}</p>
  </div>
);

const CardItem = ({ card }) => {
  const { baseColor, darkColor } = generateCardStyle(card.card_name);
  
  return (
    <div className="rounded-lg shadow-lg p-4" style={{
      background: `linear-gradient(135deg, ${baseColor}, ${darkColor})`
    }}>
      <div className="flex justify-between items-center mb-3">
        <h4 className="text-2xl font-semibold text-white">{card.card_name}</h4>
        <CreditCard className="w-6 h-6 text-white" />
      </div>
      <div className="grid grid-cols-2 gap-3">
        <InfoItem icon={DollarSign} label="Limite Total" value={formatCurrency(card.creditLimit || 0)} />
        <InfoItem icon={DollarSign} label="Limite Disponível" value={formatCurrency(card.availableLimit || 0)} />
        <InfoItem icon={FileText} label="Fatura Atual" value={formatCurrency(card.currentInvoiceValue || 0)} />
        <InfoItem icon={Calendar} label="Próximo Vencimento" value={formatCardDate(card.nextInvoiceDate)} />
        <div className="col-span-2">
          <InfoItem icon={Clock} label="Fechamento" value={`Dia ${card.validity_period_days} de cada mês`} />
        </div>
      </div>
    </div>
  );
};

const TransactionItem = ({ transaction }) => {
  const { baseColor, darkColor } = generateCardStyle(transaction.card_name);
  
  return (
    <div 
      className="rounded-xl p-4 transition-all duration-300 hover:opacity-90"
      style={{
        background: `linear-gradient(135deg, ${baseColor}, ${darkColor})`
      }}
    >
      <div className="flex items-start gap-4">
        <div className="flex-shrink-0">
          <div className="w-10 h-10 bg-white/10 rounded-full flex items-center justify-center">
            {getCategoryIcon(transaction.category)}
          </div>
        </div>
        
        <div className="flex-grow min-w-0">
          <p className="font-semibold text-white text-base leading-tight mb-1">
            {transaction.description || 'Sem descrição'}
          </p>
          <div className="text-xs text-white/80 leading-snug">
            <div>Categoria: {transaction.category || 'Outros'}</div>
            <div>Cartão: {transaction.card_name}</div>
          </div>
        </div>

        <div className="flex-shrink-0 text-right">
          <p className="text-lg font-bold text-white leading-tight mb-1">
            {formatCurrency(transaction.amount)}
          </p>
          <div className="text-xs text-white/80 leading-snug">
            {transaction.installment && (
              <div>{transaction.currentInstallment || 1}/{transaction.installment}x</div>
            )}
            <div>{format(parseDate(transaction.purchase_date), "dd MMM", { locale: ptBR })}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Cartoes = () => {
  const [cards, setCards] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const cardsData = await fetchCreditCards();
        // Let's add console.log to check the initial data
        console.log('Initial cards data:', cardsData);
        
        const transactionsData = await fetchCreditCardTransactions();
        // Let's add console.log to check transaction data before filtering
        console.log('Initial transactions data:', transactionsData);
        
        const today = startOfDay(new Date());
        const updatedCards = cardsData.map(card => {
          // Filter transactions only for this specific card
          const cardTransactions = transactionsData
            .filter(t => t.card_name === card.card_name);
            
          // Let's add console.log to check filtered transactions per card
          console.log(`Transactions for ${card.card_name}:`, cardTransactions);
          
          const closingDay = card.validity_period_days;
          const lastClosingDate = getLastClosingDate(today, closingDay);
          const nextClosingDate = addMonths(lastClosingDate, 1);
          const nextInvoiceDate = addMonths(parseDate(card.invoice_date), 1);

          const currentInvoiceValue = cardTransactions
            .filter(t => {
              const transactionDate = startOfDay(parseDate(t.purchase_date));
              return isAfter(transactionDate, lastClosingDate) && 
                     isBefore(transactionDate, nextClosingDate);
            })
            .reduce((sum, t) => sum + t.amount, 0);

          const creditLimit = parseFloat(card.limit) || 0;
          const availableLimit = Math.max(creditLimit - currentInvoiceValue, 0);

          return { 
            ...card, 
            creditLimit,
            currentInvoiceValue, 
            availableLimit, 
            nextInvoiceDate,
            nextClosingDate,
            lastClosingDate
          };
        });

        setCards(updatedCards);
        // Let's sort and take only unique transactions based on some identifier
        const uniqueTransactions = Array.from(new Map(
          transactionsData.map(transaction => [
            // Use a combination of fields that should make each transaction unique
            `${transaction.card_name}-${transaction.amount}-${transaction.purchase_date}-${transaction.description}`,
            transaction
          ])
        ).values());
        
        // Sort by date in descending order
        const sortedTransactions = uniqueTransactions.sort((a, b) => 
          parseDate(b.purchase_date).getTime() - parseDate(a.purchase_date).getTime()
        );
        
        setTransactions(sortedTransactions);
        
      } catch (err) {
        console.error('Error loading credit card data:', err);
        setError('Failed to load credit card data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  if (isLoading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  const totalLimit = cards.reduce((sum, card) => sum + (card.creditLimit || 0), 0);

  return (
    <div className="bg-gray-900 p-6 rounded-lg shadow-lg space-y-6">
      <h2 className="text-3xl font-bold text-white">Cartões de Crédito</h2>
      
      <div className="bg-green-600 p-4 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold text-white mb-2">
          Limite Total de Todos os Cartões
        </h3>
        <p className="text-4xl font-bold text-white">{formatCurrency(totalLimit)}</p>
      </div>

      <div className="space-y-4">
        {cards.map((card, index) => (
          <CardItem key={index} card={card} />
        ))}
      </div>

      <div className="space-y-4">
        <h3 className="text-xl font-semibold text-white">Últimas Transações</h3>
        {transactions.length > 0 ? (
          transactions.slice(0, 5).map((transaction, index) => (
            <TransactionItem key={index} transaction={transaction} />
          ))
        ) : (
          <div className="text-center py-8">
            <CreditCard className="mx-auto h-12 w-12 text-gray-400 mb-3" />
            <p className="text-gray-400">Nenhuma transação encontrada</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cartoes;
import React, { useState, useEffect } from 'react';
import { format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const CustomDateInput = ({ value, onChange, className }) => {
  // Formatting the value to dd/MM/yyyy
  const formattedDate = value ? format(value, 'dd/MM/yyyy', { locale: ptBR }) : '';

  const handleChange = (e) => {
    // Native date picker format: yyyy-MM-dd
    const selectedDate = parse(e.target.value, 'yyyy-MM-dd', new Date());
    if (!isNaN(selectedDate)) onChange(selectedDate);
  };

  return (
    <div className="relative w-full max-w-xs">
      {/* Native Date Input */}
      <input
        type="date"
        value={value ? format(value, 'yyyy-MM-dd') : ''}
        onChange={handleChange}
        className={`${className} w-full p-3 pl-4 pr-10 rounded-lg border text-black bg-white shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm`}
        placeholder="DD/MM/AAAA"
      />
    </div>
  );
};

export default CustomDateInput;

import React, { useState, useEffect } from 'react';
import Dashboard from '../components/Dashboard';
import { fetchDashboardData } from '../api/financialApi';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';

const DashboardPage = () => {
  const [dashboardData, setDashboardData] = useState({
    budgetData: { total: 0, remaining: 0, spent: 0 },
    transactions: [],
    budgetHeatmap: [],
    currentMonthCategories: [],
    futureExpenses: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  });
  const [endDate] = useState(() => new Date());

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await fetchDashboardData(startDate, endDate);
        setDashboardData(data);
      } catch (err) {
        console.error('Error loading dashboard data:', err);
        setError('Falha ao carregar dados. Tente novamente.');
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [startDate, endDate]);

  if (isLoading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="w-full">
      <Dashboard 
        budgetData={dashboardData.budgetData}
        transactions={dashboardData.transactions}
        budgetHeatmap={dashboardData.budgetHeatmap}
        currentMonthCategories={dashboardData.currentMonthCategories}
        futureExpenses={dashboardData.futureExpenses}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
};

export default DashboardPage;
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Home, Receipt, PieChart, CreditCard } from 'lucide-react';

const MobileContainer = ({ children }) => {
  const navItems = [
    { path: '/dashboard', label: 'Inicio', icon: Home },
    { path: '/transactions', label: 'Extrato', icon: Receipt },
    { path: '/categories', label: 'Categorias', icon: PieChart },
    { path: '/cards', label: 'Cartões', icon: CreditCard },
  ];

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col w-full">
      {/* Header */}
      <header className="bg-gray-900 px-4 py-3 border-b border-gray-800">
        <h1 className="text-xl font-bold text-white">
          Poqt-Você no controle!
        </h1>
      </header>

      {/* Top Navigation */}
      <nav className="bg-gray-900 border-b border-gray-800">
        <div className="flex justify-between px-2 py-2">
          {navItems.map(({ path, label, icon: Icon }) => (
            <NavLink
              key={path}
              to={path}
              className={({ isActive }) => `
                flex items-center justify-center px-2 py-2
                rounded-full whitespace-nowrap
                transition-colors duration-200
                ${
                  isActive
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-800 text-white hover:bg-gray-700'
                }
              `}
              style={{
                flex: `1 1 ${100 / navItems.length}%`, // Ensure even spacing
                minWidth: '0', // Allow shrinking
              }}
            >
              <Icon className="w-5 h-5 sm:w-6 mr-1" />
              <span className="text-xs sm:text-sm truncate">{label}</span>
            </NavLink>
          ))}
        </div>
      </nav>

      {/* Main Content */}
      <main className="flex-1 bg-gray-900 overflow-y-auto">
        <div className="w-full max-w-[100vw] px-4">{children}</div>
      </main>

      {/* Bottom Navigation */}
      <nav className="bg-gray-900 border-t border-gray-800 py-3">
        <div className="flex justify-between px-4">
          {navItems.map(({ path, label, icon: Icon }) => (
            <NavLink
              key={path}
              to={path}
              className={({ isActive }) => `
                flex flex-col items-center transition-colors duration-200
                ${
                  isActive
                    ? 'text-blue-400'
                    : 'text-white hover:text-blue-300'
                }
              `}
              style={{ flex: `1 1 ${100 / navItems.length}%`, minWidth: '0' }}
            >
              <Icon className="w-6 sm:w-7" />
              <span className="text-xs truncate">{label}</span>
            </NavLink>
          ))}
        </div>
      </nav>

      {/* Global Styles */}
      <style jsx global>{`
        .truncate {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      `}</style>
    </div>
  );
};

export default MobileContainer;

import React from 'react';
import { formatCurrency, formatDate } from '../utils/formatters';
import { getCategoryIcon } from '../utils/dashboardHelpers';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const FutureExpenses = ({ expenses }) => {
  return (
    <section className="bg-gray-800 rounded-lg p-6 mb-6 shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-gray-200">Gastos Futuros</h3>
        {/* Uncomment this if you want to add the link */}
        {/* <Link
          to="/future-expenses"
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out flex items-center"
        >
          Ver todos
          <ArrowRight className="ml-2 w-4 h-4" />
        </Link> */}
      </div>
      <div className="space-y-4">
        {expenses.map((expense, index) => (
          <div
            key={index}
            className="bg-gray-700 rounded-lg p-4 flex items-center justify-between space-x-6"
          >
            {/* Left side (Icon + Category Name) */}
            <div className="flex items-center space-x-4 w-2/3">
              <div className="bg-white p-2 rounded-full">
                {/* Category Icon */}
                {getCategoryIcon(expense.category, 'text-blue')}
              </div>
              <div className="flex flex-col justify-center">
                <p className="font-semibold text-sm text-gray-300">{expense.description || 'Sem descrição'}</p>
                <p className="text-xs text-gray-200">Vence em {expense.daysLeft} dias</p>
                <p className="text-xs text-gray-200 mt-1">{expense.category || 'Sem categoria'}</p>
              </div>
            </div>

            {/* Right side (Amount + Due Date + Installments) */}
            <div className="text-right flex flex-col justify-between items-end space-y-2 w-1/3">
              <p className="font-bold text-blue-400 text-lg">{formatCurrency(expense.amount)}</p>
              <p className="text-xs text-gray-400">Data de vencimento: {formatDate(expense.dueDate)}</p>
              {expense.installment > 1 && (
                <p className="text-xs text-gray-400">Parcelado: {expense.installment}x</p>
              )}
            </div>
          </div>
        ))}
      </div>
      {expenses.length === 0 && (
        <p className="text-center text-gray-400 mt-4">Nenhum gasto futuro planejado.</p>
      )}
    </section>
  );
};

export default FutureExpenses;

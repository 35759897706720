import React, { useState, useEffect, useCallback } from 'react';
import { formatCurrency, formatDate } from '../utils/formatters';
import { getCategoryIcon } from '../utils/dashboardHelpers';
import CustomDateInput from './CustomDateInput';
import { Edit2, ChevronLeft, ChevronRight, Trash2, ArrowUp, ArrowDownLeft, ArrowUpRight } from 'lucide-react';
import { updateTransaction, fetchAllTransactions, deleteTransaction } from '../api/financialApi';
const TransactionHistory = ({ 
  startDate, 
  endDate, 
  setStartDate, 
  setEndDate
}) => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paymentTypeTotals, setPaymentTypeTotals] = useState({});
  const [totalSpent, setTotalSpent] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [transactionType, setTransactionType] = useState('all');
  const [isLoading, setIsLoading] = useState(false); 
  const [error, setError] = useState(null);
  const transactionsPerPage = 10;
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');

  const sortTransactions = useCallback((transactionsToSort) => {
    return [...transactionsToSort].sort((a, b) => new Date(b.date) - new Date(a.date));
  }, []);

  const getUniqueCategories = useCallback((transactionsList) => {
    const categories = new Set();
    transactionsList.forEach(transaction => {
      if (transaction.category) {
        categories.add(transaction.category);
      }
      if (transaction.incomeType) {
        categories.add(transaction.incomeType);
      }
    });
    return ['all', ...Array.from(categories)];
  }, []);

  const calculateTotals = useCallback((transactionsToCalculate) => {
    const totals = transactionsToCalculate.reduce((acc, transaction) => {
      const amount = Math.abs(transaction.amount);
      const type = transaction.type;
      const category = transaction.category || transaction.incomeType || 'Outros';
      const paymentType = transaction.paymentType || 'Outros';
  
      // Initialize objects if they don't exist
      if (!acc[type]) {
        acc[type] = {
          categories: {},
          paymentTypes: {},
          total: 0
        };
      }
  
      // Update totals
      acc[type].categories[category] = (acc[type].categories[category] || 0) + amount;
      acc[type].paymentTypes[paymentType] = (acc[type].paymentTypes[paymentType] || 0) + amount;
      acc[type].total += amount;
  
      return acc;
    }, { expense: { categories: {}, paymentTypes: {}, total: 0 }, 
         income: { categories: {}, paymentTypes: {}, total: 0 } });
  
    setPaymentTypeTotals({
      expenses: totals.expense.paymentTypes,
      incomes: totals.income.paymentTypes
    });
    setTotalSpent(totals.expense.total);
    setTotalIncome(totals.income.total);
  }, []);




  const loadTransactions = useCallback(async () => {
    try {
      const { transactions: newTransactions } = await fetchAllTransactions(startDate, endDate);
      const sortedTransactions = sortTransactions(newTransactions);
      setTransactions(sortedTransactions);
      calculateTotals(sortedTransactions);
    } catch (error) {
      console.error('Error loading transactions:', error);
      alert('Failed to load transactions. Please try again.');
    }
  }, [startDate, endDate, calculateTotals, sortTransactions]);

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);

  useEffect(() => {
  const filteredTransactions = transactions.filter(transaction => {
    if (transactionType === 'expenses') return transaction.amount > 0;
    if (transactionType === 'incomes') return transaction.amount < 0;
    return true;
  });
    setTotalPages(Math.ceil(filteredTransactions.length / transactionsPerPage));
  }, [transactions, transactionType]);

  const handleEditStart = (transaction) => {
    setEditingTransaction({ ...transaction });
    setIsModalOpen(true);
  };

  const handleEditCancel = () => {
    setEditingTransaction(null);
    setIsModalOpen(false);
  };

  const handleEditSave = useCallback(async () => {
    if (editingTransaction && editingTransaction.id) {
      try {
        const updatedTransaction = await updateTransaction(editingTransaction);
        
        setTransactions(prevTransactions => 
          sortTransactions(prevTransactions.map(transaction => 
            transaction.id === updatedTransaction.id ? updatedTransaction : transaction
          ))
        );
        
        setIsModalOpen(false);
        setEditingTransaction(null);
        await loadTransactions();  // Refresh all transactions
        
        // Set success message
        setSuccessMessage('Transação atualizada com sucesso!');
        // Clear success message after 3 seconds
        setTimeout(() => setSuccessMessage(''), 3000);
      } catch (error) {
        console.error('TransactionHistory: Error updating transaction:', error);
        alert(`Failed to update transaction: ${error.message}`);
      }
    } else {
      console.error('TransactionHistory: Unable to update transaction. Missing data or ID.');
      alert("Cannot update this transaction: Missing required information");
    }
  }, [editingTransaction, loadTransactions, sortTransactions]);

  const handleEditChange = (field, value) => {
    setEditingTransaction(prev => ({ ...prev, [field]: value }));
  };

  const handleDateChange = async () => {
    await loadTransactions();
  };

  const handleDeleteClick = (transactionId) => {
    setTransactionToDelete(transactionId);
    setIsConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (transactionToDelete) {
      setIsLoading(true);
      setError(null);
      try {
        await deleteTransaction(transactionToDelete);
        
        setTransactions(prevTransactions => 
          prevTransactions.filter(transaction => transaction.id !== transactionToDelete)
        );
        
        setSuccessMessage('Transação excluída com sucesso!');
        setTimeout(() => setSuccessMessage(''), 3000);
  
        // Recalculate totals after deletion
        calculateTotals(transactions.filter(transaction => transaction.id !== transactionToDelete));
      } catch (error) {
        console.error('Error deleting transaction:', error);
        setError('Falha ao excluir a transação. Por favor, tente novamente.');
        setTimeout(() => setError(null), 3000);
      } finally {
        setIsLoading(false);
        setIsConfirmOpen(false);
        setTransactionToDelete(null);
      }
    }
  };

  return (
<section className="bg-gray-900 rounded-lg p-6 mb-6 shadow-md border border-gray-800">
  <h2 className="text-lg sm:text-xl font-semibold text-white mb-4">Extrato</h2>

  <div className="space-y-4">
    <CategoryFilter 
      categories={getUniqueCategories(transactions)}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
    />
    <TransactionTypeSelector 
      transactionType={transactionType} 
      setTransactionType={setTransactionType} 
    />
    <DateRangeSelector 
      startDate={startDate} 
      endDate={endDate} 
      setStartDate={setStartDate} 
      setEndDate={setEndDate} 
      onDateChange={handleDateChange} 
    />
    <TransactionSummary 
      totalSpent={totalSpent} 
      totalIncome={totalIncome} 
      transactionType={transactionType}
    />
  </div>

  <PaymentTypeCirculars 
    paymentTypeTotals={paymentTypeTotals} 
    totalSpent={totalSpent}
    totalIncome={totalIncome}
    transactionType={transactionType}
  />

  {successMessage && (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div className="bg-green-600 text-white text-sm md:text-base p-4 rounded-lg shadow-lg transition-opacity duration-500">
        {successMessage}
      </div>
    </div>
  )}

  {error && (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div className="bg-red-600 text-white text-sm md:text-base p-4 rounded-lg shadow-lg transition-opacity duration-500">
        {error}
      </div>
    </div>
  )}

  <TransactionList 
    transactions={transactions}
    transactionType={transactionType}
    currentPage={currentPage}
    transactionsPerPage={transactionsPerPage}
    onEditStart={handleEditStart}
    onDelete={handleDeleteClick}
    selectedCategory={selectedCategory}
  />

  <ConfirmDialog 
    isOpen={isConfirmOpen}
    onClose={() => setIsConfirmOpen(false)}
    onConfirm={handleConfirmDelete}
    message="Tem certeza que deseja apagar?"
    isLoading={isLoading}
  />

  <Pagination 
    currentPage={currentPage} 
    totalPages={totalPages} 
    onPageChange={setCurrentPage} 
  />

  {isModalOpen && editingTransaction && (
    <EditTransactionModal
      transaction={editingTransaction}
      onCancel={handleEditCancel}
      onSave={handleEditSave}
      onChange={handleEditChange}
    />
  )}
</section>
  );
};

const TransactionTypeSelector = ({ transactionType, setTransactionType }) => (
  <div className="flex justify-center space-x-1 mb-6 mx-auto max-w-screen-md px-4">
    {['all', 'expenses', 'incomes'].map((type) => (
      <button
        key={type}
        onClick={() => setTransactionType(type)}
        className={`px-6 py-2 rounded-full text-sm sm:text-base lg:text-lg transition-colors duration-200 ${
          transactionType === type
            ? 'bg-blue-600 text-white shadow-md'
            : 'bg-gray-700 text-gray-200 hover:bg-gray-600 hover:text-white'
        }`}
      >
        {type === 'all' ? 'Todos' : type === 'expenses' ? 'Gastos' : 'Recebidos'}
      </button>
    ))}
  </div>
);



const CategoryFilter = ({ categories, selectedCategory, setSelectedCategory }) => (
  <div className="flex justify-center mb-6">
    <select
      value={selectedCategory}
      onChange={(e) => setSelectedCategory(e.target.value)}
      className="bg-gray-800 text-white text-base sm:text-lg px-6 py-3 rounded-full border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
    >
      {categories.map((category) => (
        <option key={category} value={category}>
          {category === 'all' ? 'Todas as Categorias' : category}
        </option>
      ))}
    </select>
  </div>
);

const ConfirmDialog = ({ isOpen, onClose, onConfirm, message, isLoading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-80 sm:w-96">
        <p className="text-base sm:text-lg mb-6">{message}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm sm:text-base bg-gray-600 rounded hover:bg-gray-700 transition disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading}
          >
            Cancelar
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm sm:text-base bg-red-600 rounded hover:bg-red-700 transition disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading}
          >
            {isLoading ? 'Excluindo...' : 'Excluir'}
          </button>
        </div>
      </div>
    </div>
  );
};


const DateRangeSelector = ({ startDate, endDate, setStartDate, setEndDate, onDateChange }) => (
  <div className="flex justify-center text-2xl items-center mb-6">
    <div className="flex space-x-4">
      <CustomDateInput
        value={startDate}
        onChange={setStartDate}
        className="bg-white text-black p-2 rounded"
      />
      <CustomDateInput
        value={endDate}
        onChange={setEndDate}
        className="bg-white text-black p-2 rounded"
      />
    </div>
  </div>
);

const TransactionSummary = ({ totalSpent, totalIncome, transactionType }) => {
  return (
    <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-8 mb- shadow-2xl">
      <h3 className="text-3xl font-extrabold mb-6 text-white tracking-tight">Resumo Financeiro</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <SummaryCard
          title="Total Recebido"
          amount={totalIncome}
          icon={ArrowUp}
          color="green"
          show={transactionType === 'all' || transactionType === 'incomes'}
        />
        <SummaryCard
          title="Total Gasto"
          amount={totalSpent}
          icon={ArrowDownLeft}
          color="red"
          show={transactionType === 'all' || transactionType === 'expenses'}
        />
      </div>
    </div>
  );
};

const SummaryCard = ({ title, amount, color, show }) => {
  if (!show) return null;

  const colorClasses = {
    green: 'from-green-400/20 to-green-600/20 text-green-300 border-green-400/50',
    red: 'from-red-400/20 to-red-600/20 text-red-300 border-red-400/50'
  };

  return (
    <div className={`bg-gradient-to-br ${colorClasses[color]} rounded-xl p-6 shadow-lg border border-opacity-50 backdrop-blur-sm transform transition-all duration-300 hover:scale-105 hover:shadow-2xl`}>
      <div className="flex items-center justify-between mb-2">
        <p className="font-semibold text-lg">{title}</p>
      </div>
      <p className="text-s font-bold text-white mb-2">{formatCurrency(amount)}</p>
      <div className={`w-full bg-${color}-200 bg-opacity-30 h-1 rounded-full overflow-hidden`}>
        <div 
          className={`bg-${color}-400 h-full rounded-full transition-all duration-500 ease-out`}
          style={{ width: `${Math.min((amount / 10000) * 100, 100)}%` }}
        ></div>
      </div>
    </div>
  );
};


const PaymentTypeCirculars = ({ paymentTypeTotals, totalSpent, totalIncome, transactionType }) => (
  <div className="space-y-8 mb-6">
    {(transactionType === 'all' || transactionType === 'expenses') && (
      <PaymentTypeSection
        title="Gastos por Forma de Pagamento"
        total={totalSpent}
        data={paymentTypeTotals.expenses}
        type="expense"
      />
    )}
    {(transactionType === 'all' || transactionType === 'incomes') && (
      <PaymentTypeSection
        title="Ganhos por Forma de Pagamento"
        total={totalIncome}
        data={paymentTypeTotals.incomes}
        type="income"
      />
    )}
  </div>
);

const PaymentTypeSection = ({ title, total, data, type }) => (
  <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-2xl p-6 md:p-8 shadow-2xl">
    <h3 className="text-lg md:text-2xl font-extrabold mb-4 text-white tracking-tight">{title}</h3>
    <p className="text-sm md:text-lg text-gray-300 mb-4">Total: {formatCurrency(total)}</p>
    <div className="space-y-4">
      {Object.entries(data || {})
        .sort((a, b) => b[1] - a[1])
        .map(([paymentType, amount]) => (
          <PaymentTypeBar
            key={`${type}-${paymentType}`}
            paymentType={paymentType}
            amount={amount}
            percent={(amount / total) * 100}
            isIncome={type === 'income'}
          />
        ))}
    </div>
  </div>
);

const PaymentTypeBar = ({ paymentType, amount, percent, isIncome }) => {
  const color = isIncome ? 'green' : 'red';

  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-center justify-center w-10 h-10 flex-shrink-0 bg-white rounded-full">
        {getCategoryIcon(paymentType)}
      </div>
      <div className="flex-grow">
        <div className="flex justify-between items-center mb-1">
          <span className="text-xs md:text-sm font-medium text-gray-300">{paymentType}</span>
          <span className="text-xs md:text-sm font-bold text-white">{formatCurrency(amount)}</span>
        </div>
        <div className="w-full bg-gray-700 rounded-full h-3 md:h-4 overflow-hidden">
          <div
            className={`bg-${color}-500 h-full rounded-full transition-all duration-500 ease-out`}
            style={{ width: `${percent}%` }}
          ></div>
        </div>
      </div>
      <span className="text-xs md:text-sm font-medium text-gray-400 w-16 text-right">
        {percent.toFixed(1)}%
      </span>
    </div>
  );
};


const TransactionList = ({
  transactions,
  transactionType,
  currentPage,
  transactionsPerPage,
  onEditStart,
  onDelete,
  selectedCategory
}) => {
  const filteredTransactions = transactions.filter(transaction => {
    // Type filter
    if (transactionType === 'expenses' && transaction.type !== 'expense') return false;
    if (transactionType === 'incomes' && transaction.type !== 'income') return false;

    // Category filter
    if (selectedCategory !== 'all') {
      const transactionCategory = transaction.category || transaction.incomeType;
      if (transactionCategory !== selectedCategory) return false;
    }

    return true;
  });

  const paginatedTransactions = filteredTransactions.slice(
    (currentPage - 1) * transactionsPerPage,
    currentPage * transactionsPerPage
  );

  return (
    <div className="space-y-4">
      {paginatedTransactions.map(transaction => (
        <TransactionItem
          key={transaction.id}
          transaction={transaction}
          onEditStart={onEditStart}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

const TransactionItem = ({ transaction, onEditStart, onDelete }) => {
  const isIncome = transaction.type === 'income';
  const colorClass = isIncome ? 'from-green-400/20 to-green-600/20' : 'from-red-400/20 to-red-600/20';
  const borderColorClass = isIncome ? 'border-green-400/50' : 'border-red-400/50';
  const textColorClass = isIncome ? 'text-green-200' : 'text-red-200';

  return (
    <div
      className={`bg-gradient-to-br ${colorClass} rounded-xl p-3 flex items-center justify-between border ${borderColorClass} shadow-md transition-all duration-300 hover:shadow-lg`}
    >
      <div className="flex items-center min-w-0 flex-1 space-x-3">
        <div
          className={`p-0.5 rounded-full flex-shrink-0  ${isIncome ? 'bg-green-100' : 'bg-red-100'}`}
        >
          {isIncome ? (
            <ArrowUp className="w-8 h-8 mr-3 bg-white rounded-full" />
          ) : (
            getCategoryIcon(transaction.category, { className: 'w-8 h-8 mr-3 bg-white rounded-full' })
          )}
        </div>
        <div className="min-w-0 flex-grow">
          <p className="text-sm font-semibold text-white break-words">
            {transaction.name || 'Sem descrição'}
          </p>
          <p className="text-sm text-gray-300 break-words">
            {isIncome ? transaction.incomeType : transaction.category || 'Sem categoria'}
          </p>
        </div>
      </div>
      <div className="flex items-center space-x-2 flex-shrink-0">
        <div className="text-right">
          <p className={`text-sm text-white mb-4 font-bold ${textColorClass} whitespace-nowrap`}>
            {formatCurrency(Math.abs(transaction.amount))}
          </p>
          <p className="text-xs text-white">{formatDate(new Date(transaction.date))}</p>
        </div>
        <button
          onClick={() => onDelete(transaction.id)}
          className="p-1 hover:bg-gray-700 rounded-full transition-colors duration-200"
        >
          <Trash2 size={25} className="text-black hover:text-white" />
        </button>
        <button
          onClick={() => onEditStart(transaction)}
          className="p-1 hover:bg-gray-700 rounded-full transition-colors duration-200"
        >
          <Edit2 size={25} className="text-black hover:text-white" />
        </button>
      </div>
    </div>
  );
};


const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex justify-center mt-4 gap-x-3">
    <button
      onClick={() => onPageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className="flex items-center px-4 py-2 bg-blue-600 rounded-full disabled:bg-gray-600 transition-colors"
    >
      <ChevronLeft size={20} className="mr-1" />
      Anterior
    </button>
    <span className="py-2 text-sm font-semibold">
      Página {currentPage} de {totalPages}
    </span>
    <button
      onClick={() => onPageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className="flex items-center px-4 py-2 bg-blue-600 rounded-full disabled:bg-gray-600 transition-colors"
    >
      Próxima
      <ChevronRight size={20} className="ml-1" />
    </button>
  </div>
);

const EditTransactionModal = ({ transaction, onCancel, onSave, onChange }) => {
  const [displayAmount, setDisplayAmount] = useState(formatCurrency(transaction.amount));

  useEffect(() => {
    setDisplayAmount(formatCurrency(transaction.amount));
  }, [transaction.amount]);

  const handleAmountChange = e => {
    const rawValue = e.target.value.replace(/R\$|\./g, '').replace(',', '.');
    const numericValue = parseFloat(rawValue);

    if (!isNaN(numericValue)) {
      setDisplayAmount(e.target.value);
      onChange('amount', numericValue);
    } else {
      setDisplayAmount('');
      onChange('amount', 0);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md max-h-screen overflow-y-auto">
        <h3 className="text-xl font-bold mb-4">Editar Transação</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-400">Descrição</label>
            <input
              type="text"
              value={transaction.name}
              onChange={e => onChange('name', e.target.value)}
              className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400">Categoria</label>
            <input
              type="text"
              value={transaction.category}
              onChange={e => onChange('category', e.target.value)}
              className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400">Subcategoria</label>
            <input
              type="text"
              value={transaction.subcategory || ''}
              onChange={(e) => onChange('subcategory', e.target.value)}
              className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-white"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400">Forma de Pagamento</label>
            <input
              type="text"
              value={transaction.paymentType || ''}
              onChange={(e) => onChange('paymentType', e.target.value)}
              className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-white"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400">Valor</label>
            <input
              type="text"
              inputMode="decimal"
              pattern="[0-9]*"
              value={displayAmount}
              onChange={handleAmountChange}
              placeholder="R$ 0,00"
              className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-400">Data (Mês/Dia/Ano)</label>
            <input
              type="date"
              value={
                transaction.date instanceof Date
                  ? transaction.date.toISOString().split('T')[0]
                  : transaction.date
              }
              onChange={e => onChange('date', e.target.value)}
              className="mt-1 block w-full bg-gray-700 border border-gray-600 rounded-md shadow-sm py-2 px-3 text-white"
            />
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-3">
          <button onClick={onCancel} className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700">
            Cancelar
          </button>
          <button onClick={onSave} className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
};




export default TransactionHistory;
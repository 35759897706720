// src/api/financialApi.js
import api from './axiosConfig';

// Dashboard Data
export const fetchDashboardData = async () => {
  try {
    const response = await api.get('/dashboard_data');
    return response.data;
  } catch (error) {
    //console.log.error('Error fetching dashboard data:', error);
    throw error;
  }
};

// Transactions
export const fetchAllTransactions = async (startDate, endDate) => {
  if (!startDate || !endDate) {
    //console.log.error('Start date or end date is missing');
    return { transactions: [], totalSpent: 0 };
  }
  try {
    const response = await api.get('/transactions/all', {
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }
    });
    
    if (!response.data || !Array.isArray(response.data.transactions)) {
      throw new Error('Invalid response format from API');
    }

    const sortedTransactions = response.data.transactions
      .map(transaction => ({
        ...transaction,
        id: transaction.id,
        date: new Date(transaction.date + 'T00:00:00')
      }))
      .sort((b, a) => b.date - a.date);

    return {
      transactions: sortedTransactions,
      totalSpent: response.data.totalSpent,
      totalIncome: response.data.totalIncome
    };
  } catch (error) {
    //console.log.error('Error fetching all transactions:', error);
    throw error;
  }
};

export const updateTransaction = async (updatedTransaction) => {
  if (!updatedTransaction.id) {
    throw new Error("Transaction ID is required for update");
  }
  try {
    const response = await api.put(`/transactions/${updatedTransaction.id}`, updatedTransaction);
    //console.log.log("Transaction update response:", response.data);
    return response.data;
  } catch (error) {
    //console.log.error('Error updating transaction:', error);
    throw error;
  }
};

export const deleteTransaction = async (transactionId) => {
  try {
    const response = await api.delete(`/transactions/${transactionId}`);
    return response.data;
  } catch (error) {
    //console.log.error('Error deleting transaction:', error);
    throw error;
  }
};

// Budgets
export const fetchAllBudgets = async () => {
  try {
    const response = await api.get('/budgets/all');
    return response.data;
  } catch (error) {
    //console.log.error('Error fetching all budgets:', error);
    throw error;
  }
};

// Upcoming Payments
export const fetchAllUpcoming = async () => {
  try {
    const response = await api.get('/upcoming/all');
    return response.data;
  } catch (error) {
    //console.log.error('Error fetching all upcoming payments:', error);
    throw error;
  }
};

// Review Status
export const markAsReviewed = async () => {
  try {
    const response = await api.post('/mark_as_reviewed');
    return response.data;
  } catch (error) {
    //console.log.error('Error marking transactions as reviewed:', error);
    throw error;
  }
};

// Credit Cards
export const fetchCreditCards = async () => {
  try {
    const response = await api.get('/credit_cards');
    return response.data;
  } catch (error) {
    //console.log.error('Error fetching credit cards:', error);
    throw error;
  }
};

export const fetchCreditCardTransactions = async () => {
  try {
    const response = await api.get('/credit_card_transactions');
    return response.data;
  } catch (error) {
    //console.log.error('Error fetching credit card transactions:', error);
    throw error;
  }
};

// Recurring Transactions
export const fetchRecurringTransactions = async () => {
  try {
    const response = await api.get('/recurring_transactions');
    return response.data;
  } catch (error) {
    //console.log.error('Error fetching recurring transactions:', error);
    throw error;
  }
};

// Categories
export const fetchCategories = async () => {
  try {
    const response = await api.get('/categories');
    return response.data;
  } catch (error) {
    //console.log.error('Error fetching categories:', error);
    throw error;
  }
};

// Budget Heatmap
export const fetchBudgetHeatmap = async (startDate, endDate) => {
  try {
    const response = await api.get('/budget_heatmap', {
      params: { startDate, endDate }
    });
    return response.data;
  } catch (error) {
    //console.log.error('Error fetching budget heatmap:', error);
    throw error;
  }
};

// Future Expenses
export const fetchFutureExpenses = async () => {
  try {
    const response = await api.get('/future_expenses');
    return response.data;
  } catch (error) {
    //console.log.error('Error fetching future expenses:', error);
    throw error;
  }
};

// Utility function to format API errors
export const formatApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return error.response.data.error || 'An error occurred with the request';
  } else if (error.request) {
    // The request was made but no response was received
    return 'No response received from server';
  } else {
    // Something happened in setting up the request that triggered an Error
    return error.message || 'An unexpected error occurred';
  }
};

import React, { useState, useEffect } from 'react';
import Categories from '../components/Categories';
import { fetchDashboardData, fetchAllTransactions } from '../api/financialApi';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const dashboardData = await fetchDashboardData();
        setCategories(dashboardData.currentMonthCategories);

        const { transactions } = await fetchAllTransactions(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date()
        );
        setTransactions(transactions);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return <Categories categories={categories} transactions={transactions} />;
};

export default CategoriesPage;
// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });
  const [token, setToken] = useState(() => localStorage.getItem('token'));
  const [phoneNumber, setPhoneNumber] = useState(() => localStorage.getItem('phoneNumber'));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const login = (newToken, phone) => {
    localStorage.setItem('token', newToken);
    localStorage.setItem('phoneNumber', phone);
    localStorage.setItem('isAuthenticated', 'true');
    setToken(newToken);
    setPhoneNumber(phone);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.clear();
    setToken(null);
    setPhoneNumber(null);
    setIsAuthenticated(false);
    window.location.href = '/login';
  };

  const value = {
    isAuthenticated,
    token,
    phoneNumber,
    isLoading,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
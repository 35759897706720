// utils/dashboardHelpers.js
import * as icons from 'lucide-react';
import React from 'react';

export const getFinancialHealthStatus = (spent, total) => {
  const spentPercentage = (spent / total) * 100;
  if (spentPercentage < 50) return { text: 'Excelente', color: 'bg-green-500', icon: 'TrendingUp' };
  if (spentPercentage < 75) return { text: 'Bom', color: 'bg-yellow-500', icon: 'TrendingUp' };
  if (spentPercentage < 90) return { text: 'Atenção', color: 'bg-orange-500', icon: 'AlertCircle' };
  return { text: 'Crítico', color: 'bg-red-500', icon: 'TrendingDown' };
};

export const getCategoryIcon = (category, isOverLimit = false) => {
  const iconName = {
      // Categoria ícones
      'transporte': 'Car',
      'alimentação': 'Pizza',
      'saúde': 'Heart',
      'moradia': 'Home',
      'educação': 'Book',
      'lazer': 'Music',
      'compras': 'ShoppingCart',
      'vestuário': 'Shirt',
      'eletrônicos': 'Smartphone',
      'viagem': 'Plane',
      'impostos': 'Receipt',
      'entretenimento': 'Film',
      'cuidados pessoais': 'Spa',
      'higiene pessoal': 'Spa',
      'segurança': 'Shield',
      'serviços': 'Tool',
      'doações': 'HandHeart',
      'pets': 'Paw',
      'investimentos': 'TrendingUp',
      'poupança': 'PiggyBank',
      'assinaturas': 'FileText',
      'jogos': 'Gamepad',
      'energia': 'BatteryCharging',
      'telefone': 'Phone',
      'internet': 'Wifi',
      'manutenção': 'Wrench',
      
      // Novas categorias adicionadas
      'supermercado': 'ShoppingBag',
      'farmácia': 'Pill',
      'bares e restaurantes': 'Beer',
      'academia': 'Barbell',
      'educação infantil': 'Child',
      'festas': 'Gift',
      'combustível': 'FuelPump',
      'transporte público': 'Bus',
      'autoescola': 'SteeringWheel',
      'táxi': 'Taxi',
      'ride sharing': 'Car',
      'lavanderia': 'Water',
      'salão de beleza': 'Scissors',
      'material escolar': 'PenTool',
      'móveis': 'Chair',
      'decoração': 'Image',
      'brinquedos': 'Toy',
      'livros': 'BookOpen',
      'cinema': 'Film',
      'teatro': 'Mic',
      'música ao vivo': 'Music',
      'parques': 'Tree',
      'esportes': 'Football',
      
      // Novas adições ainda mais específicas
      'transporte aéreo': 'Airplane', // Air transport
      'transporte marítimo': 'Anchor', // Sea transport
      'bicicleta': 'Bike', // Bicycle
      'moto': 'Motorcycle', // Motorcycle
      'educação superior': 'GraduationCap', // Higher education
      'autopeças': 'CarRepair', // Auto parts
      'consultoria': 'Briefcase', // Consulting
      'frete': 'Truck', // Freight
      'entrega de comida': 'Package', // Food delivery
      'hotelaria': 'Bed', // Hotels
      'imobiliária': 'Building', // Real estate
      'manicure': 'Scissors', // Manicure services
      'barbearia': 'Scissors', // Barbershop
      'psicologia': 'User', // Psychological services
      'fisioterapia': 'Healing', // Physiotherapy
      'veterinário': 'Heartbeat', // Veterinary services
      'hortifruti': 'Leaf', // Fruits and vegetables
      'floricultura': 'Flower', // Floriculture
      'papelaria': 'Paperclip', // Stationery
      'fotografia': 'Camera', // Photography
      'sorveteria': 'IceCream', // Ice cream shop
      'padaria': 'Bread', // Bakery
      'açougue': 'Drumstick', // Butcher shop
      'pizzaria': 'Pizza', // Pizzeria
      'fast food': 'Hamburger', // Fast food
      'mercado financeiro': 'DollarSign', // Financial market
      'roupas íntimas': 'Underwear', // Lingerie or underwear
      'costura': 'Scissors', // Tailoring
      'artesanato': 'Scissors', // Craftsmanship
      'jardinagem': 'Leaf', // Gardening
      'construção': 'Hammer', // Construction
      'mudança': 'Truck', // Moving services
      'mídias sociais': 'Share', // Social media
      'streaming': 'PlayCircle', // Streaming services
      'aluguel': 'Key', // Rent
      'taxas bancárias': 'Bank', // Bank fees
      'pedágio': 'Toll', // Toll fees
      'licenciamento': 'FileText', // Licensing (vehicles)
      'consultoria jurídica': 'Gavel', // Legal consulting
      'planos de saúde': 'HeartPulse', // Health plans
      'seguros': 'ShieldCheck', // Insurance
      'turismo': 'MapPin', // Tourism
      'marketing': 'Megaphone', // Marketing services
  
      // Tipos de pagamento (PT-BR)
      'dinheiro': 'Banknote',
      'cartão de crédito': 'CreditCard',
      'cartão de débito': 'Wallet',
      'débito': 'Wallet',
      'pix': 'Zap',
      'à vista': 'CreditCard',
      'boleto': 'Receipt',
      'parcelado': 'Calendar',
      'transferência': 'ArrowRightLeft',
      'vale-presente': 'Gift',
      'recarga de celular': 'Mobile',
      'consórcio': 'Building',
      'financiamento': 'Home',
      'cheque': 'CheckCircle',
      'crédito rotativo': 'CreditCard',
      'promoção': 'Tag',
      'desconto': 'Discount',
      'carteira digital': 'Wallet',
  
      // Se não encontrar categoria
      'default': 'HelpCircle',
  }[category?.toLowerCase()] || 'HelpCircle';
  const IconComponent = icons[iconName];
  return IconComponent ? <IconComponent className={`w-8 h-8 ${isOverLimit ? 'text-red-500' : ''}`} /> : null;
};

export const getHeatmapColor = (percent) => {
  if (percent >= 90) return '#FF4136';
  if (percent >= 70) return '#FF851B';
  if (percent >= 50) return '#FFDC00';
  return '#2ECC40';
};

export const getHeatmapColorBars = (percent) => {
  if (percent >= 90) return 'bg-red-500';
  if (percent >= 70) return 'bg-orange-500';
  if (percent >= 50) return 'bg-yellow-500';
  return 'bg-green-500';
};
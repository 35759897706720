// src/api/authApi.js
import api from './axiosConfig';

export const sendOTP = async (phoneNumber) => {
  try {
    ////console.log('Sending OTP request for:', phoneNumber); // Debug log
    const response = await api.post('/auth/send-otp', { phoneNumber });
    ////console.log('Send OTP response:', response.data); // Debug log
    return response.data;
  } catch (error) {
    console.error('Send OTP error:', error.response?.data || error); // Debug log
    throw error;
  }
};

export const verifyOTP = async (phoneNumber, otp) => {
  try {
    //console.log('Verifying OTP for:', { phoneNumber, otp }); // Debug log
    const response = await api.post('/auth/verify-otp', { phoneNumber, otp });
    //console.log('Verify OTP response:', response.data); // Debug log
    
    if (response.data.isValid && response.data.token) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('phoneNumber', phoneNumber);
      localStorage.setItem('isAuthenticated', 'true');
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
    }
    return response.data;
  } catch (error) {
    console.error('Verify OTP error:', error.response?.data || error); // Debug log
    throw error;
  }
};
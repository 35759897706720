// src/components/Dashboard.js
import React, { useState } from 'react';
import { formatCurrency } from '../utils/formatters';
import { getFinancialHealthStatus, getCategoryIcon, getHeatmapColor } from '../utils/dashboardHelpers';
import CircularProgress from './CircularProgress';
import BudgetHeatmap from './BudgetHeatmap';
import TransactionList from './TransactionList';
import FutureExpenses from './FutureExpenses';
import { ChevronLeft, ChevronRight, ArrowDownRight, DollarSign, ArrowUpRight, TrendingUp, AlertCircle, TrendingDown } from 'lucide-react';

const Dashboard = ({ budgetData, transactions, budgetHeatmap, currentMonthCategories, futureExpenses }) => {    
  const healthStatus = getFinancialHealthStatus(budgetData.spent, budgetData.total);
  const sortedCategories = [...currentMonthCategories].sort((a, b) => {
    const percentSpentA = a.total ? ((a.total - a.amount) / a.total) * 100 : 0;
    const percentSpentB = b.total ? ((b.total - b.amount) / b.total) * 100 : 0;
    if (percentSpentB !== percentSpentA) {
      return percentSpentB - percentSpentA;
    }
    return (b.total - b.amount) - (a.total - a.amount);
  });

  return (
    <>
      <FinancialStatusSection budgetData={budgetData} healthStatus={healthStatus} />
      {sortedCategories && sortedCategories.length > 0 ? (
        <CategoryBudgetSection 
          categories={sortedCategories} 
          categoriesPerPage={4}
        />
      ) : (
        <p>No budget categories available for the current month.</p>
      )}
      <BudgetHeatmap heatmapData={budgetHeatmap} />
      <TransactionList transactions={transactions} />
      <FutureExpenses expenses={futureExpenses} />
    </>
  );
};

const FinancialStatusSection = ({ budgetData, healthStatus }) => {
  const HealthIcon = { TrendingUp, AlertCircle, TrendingDown }[healthStatus.icon];

  return (
    <section className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-sm rounded-2xl p-8 mb-8 shadow-2xl border border-gray-700/30">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4 mb-8">
        <div className="space-y-2">
          <h2 className="text-2xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Situação Financeira
          </h2>
          <p className="text-gray-400 text-sm">Visão geral do seu orçamento</p>
        </div>
        <div className={`flex items-center ${healthStatus.color} bg-opacity-20 backdrop-blur-sm text-white px-6 py-3 rounded-2xl border border-white/10 shadow-lg`}>
          <HealthIcon className="w-6 h-6 mr-3" />
          <span className="font-bold text-lg">{healthStatus.text}</span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <FinancialStatusItem
          icon="ArrowDownRight"
          color="from-red-500/20 to-red-600/20"
          borderColor="border-red-500/30"
          textColor="text-red-400"
          label="Gasto"
          value={budgetData.spent}
        />
        <FinancialStatusItem
          icon="DollarSign"
          color="from-blue-500/20 to-blue-600/20"
          borderColor="border-blue-500/30"
          textColor="text-blue-400"
          label="Planejado"
          value={budgetData.total}
        />
        <FinancialStatusItem
          icon="ArrowUpRight"
          color="from-green-500/20 to-green-600/20"
          borderColor="border-green-500/30"
          textColor="text-green-400"
          label="Restante"
          value={budgetData.remaining}
        />
      </div>
    </section>
  );
};

const FinancialStatusItem = ({ icon, label, value, color, borderColor, textColor }) => {
  const IconComponent = { ArrowDownRight, DollarSign, ArrowUpRight }[icon];
  
  return (
    <div className={`bg-gradient-to-br ${color} rounded-xl p-6 border ${borderColor} shadow-lg transition-all duration-300 hover:scale-105 hover:shadow-xl group`}>
      <div className="flex items-center space-x-3 mb-3">
        <div className="p-3 rounded-lg bg-gray-800/50 group-hover:bg-gray-800/70">
          <IconComponent className={`w-6 h-6 ${textColor}`} />
        </div>
        <h3 className="text-gray-300 font-medium">{label}</h3>
      </div>
      <p className={`text-2xl font-bold ${textColor}`}>
        {formatCurrency(value)}
      </p>
      <div className="mt-4 w-full bg-gray-700/30 h-1 rounded-full overflow-hidden">
        <div 
          className={`h-full rounded-full ${textColor} opacity-50`}
          style={{ width: `${Math.min((value / value) * 100, 100)}%` }}
        />
      </div>
    </div>
  );
};


const CategoryBudgetSection = ({ categories, categoriesPerPage }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(categories.length / categoriesPerPage);

  return (
    <section className="mb-6 relative">
      <h3 className="text-lg md:text-xl font-semibold mb-4 text-gray-200">
        Quanto você ainda pode gastar por categoria
      </h3>
      <div className="flex space-x-6 sm:space-x-8 items-center overflow-x-auto pb-4 hide-scrollbar">
        {categories
          .slice(currentPage * categoriesPerPage, (currentPage + 1) * categoriesPerPage)
          .map((category, index) => (
            <CategoryBudgetItem key={index} category={category} />
          ))}
      </div>
      <PaginationButtons
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </section>
  );
};


const CategoryBudgetItem = ({ category }) => {
  const spent = category.total - category.amount;
  const percentSpent = category.total ? (spent / category.total) * 100 : 0;
  const isOverLimit = percentSpent > 100;

  return (
    <div className="flex flex-col items-center w-32 sm:w-36 space-y-8"> {/* Increased space between elements */}
      <CircularProgress
        percent={Math.min(percentSpent, 100)}
        color={getHeatmapColor(percentSpent)}
        isOverLimit={isOverLimit}
      >
        {getCategoryIcon(category.name, isOverLimit)}
      </CircularProgress>
      <div className="mt-6 text-center space-y-2"> {/* Increased margin for separation */}
        <p className="text-sm font-medium truncate text-gray-300">
          {category.name}
        </p>
        <p className="text-xs font-bold text-gray-100">
          {formatCurrency(category.amount)}
        </p>
        <p className="text-xs text-gray-200">Restante</p>
      </div>
    </div>
  );
};






const PaginationButtons = ({ currentPage, setCurrentPage, totalPages }) => {
  return (
    <div className="flex justify-between mt-6 px-4"> {/* Align buttons to the left and right corners */}
      <button
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
        className={`${
          currentPage === 0
            ? 'text-gray-500 cursor-not-allowed'
            : 'text-blue-400 hover:text-blue-500'
        }`}
        disabled={currentPage === 0}
      >
        <ChevronLeft className="w-5 h-5" /> {/* Set consistent icon size */}
      </button>
      <button
        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
        className={`${
          currentPage === totalPages - 1
            ? 'text-gray-500 cursor-not-allowed'
            : 'text-blue-400 hover:text-blue-500'
        }`}
        disabled={currentPage === totalPages - 1}
      >
        <ChevronRight className="w-5 h-5" /> {/* Set consistent icon size */}
      </button>
    </div>
  );
};





export default Dashboard;
import React from 'react';
import { formatCurrency } from '../utils/formatters';
import { getCategoryIcon } from '../utils/dashboardHelpers';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { format, parse } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const TransactionList = ({ transactions }) => {
  // Helper function to format the date string
  const formatTransactionDate = (dateString) => {
    const date = parse(dateString, 'yyyy-MM-dd', new Date());
    return format(date, 'dd/MM/yyyy', { locale: ptBR });
  };

  return (
    <section className="bg-gray-800 rounded-lg p-6 mb-6 shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold text-gray-200">Últimas Transações</h3>
        <Link
          to="/transactions"
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out flex items-center"
        >
          Ver Extrato
          <ArrowRight className="ml-2 w-4 h-4" />
        </Link>
      </div>
      <div className="space-y-6">
        {transactions.map((transaction, index) => (
          <div
            key={index}
            className="bg-gray-700 rounded-lg p-4 flex items-center justify-between mb-4"
          >
            <div className="flex items-center space-x-5">
              <div className="bg-white p-2 rounded-full">
                {/* Apply specific color to category icon */}
                {getCategoryIcon(transaction.category, 'text-white')} {/* Example: Yellow for categories */}
              </div>
              <div>
                <p className="font-semibold text-gray-300">
                  {transaction.name || 'Sem descrição'}
                </p>
                <p className="text-lg text-red-300">
                  {transaction.category || 'Sem categoria'}
                  {transaction.subcategory && ` > ${transaction.subcategory}`}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-5">
              <div className="text-right">
                <p
                  className={`font-bold ${
                    transaction.amount < 0 ? 'text-red-400' : 'text-green-400'
                  }`}
                >
                  {formatCurrency(Math.abs(transaction.amount))}
                </p>
                <p className="text-sm text-white">
                  {formatTransactionDate(transaction.date)}
                </p>
              </div>
              <div title={transaction.paymentType || 'Sem tipo de pagamento'}>
                {/* Apply specific color to payment type icon */}
                {getCategoryIcon(transaction.paymentType, 'text-white')} {/* Example: Blue for payment type */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TransactionList;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ArrowUpRight, ArrowDownRight, TrendingUp, TrendingDown, AlertCircle } from 'lucide-react';
import { formatCurrency } from '../utils/formatters';
import { getCategoryIcon, getHeatmapColorBars } from '../utils/dashboardHelpers';
import CustomDateInput from './CustomDateInput';
import { fetchDashboardData, fetchAllTransactions } from '../api/financialApi';
import LoadingSpinner from './LoadingSpinner';

const Categories = ({ categories }) => {
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [categoryData, setCategoryData] = useState([]);
  const [topCategories, setTopCategories] = useState([]);
  const [bottomCategories, setBottomCategories] = useState([]);
  const [error, setError] = useState(null);

  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [budgetedCategories, setBudgetedCategories] = useState([]);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const dashboardData = await fetchDashboardData(startDate, endDate);
      const { transactions } = await fetchAllTransactions(startDate, endDate);

      // Create a Map to deduplicate categories
      const categoryMap = new Map();

      // Process all transactions first
      transactions.forEach(transaction => {
        if (transaction.type === 'expense' && transaction.category) {
          const key = transaction.category.toLowerCase();
          if (!categoryMap.has(key)) {
            categoryMap.set(key, {
              name: transaction.category,
              spent: 0,
              total: 0,
              transactions: []
            });
          }
          const category = categoryMap.get(key);
          category.spent += Math.abs(transaction.amount);
          category.transactions.push(transaction);
        }
      });

      // Add budget information
      dashboardData.currentMonthCategories.forEach(budgetCat => {
        const key = budgetCat.name.toLowerCase();
        if (categoryMap.has(key)) {
          const existing = categoryMap.get(key);
          existing.total = budgetCat.total;
        } else {
          categoryMap.set(key, {
            name: budgetCat.name,
            spent: 0,
            total: budgetCat.total,
            transactions: []
          });
        }
      });

      // Convert Map to array and calculate additional metrics
      const processedCategories = Array.from(categoryMap.values()).map(cat => ({
        ...cat,
        percentUsed: cat.total > 0 ? (cat.spent / cat.total) * 100 : 0,
        remaining: Math.max(cat.total - cat.spent, 0)
      }));

      setCategoryData(processedCategories);
      setTransactions(transactions);

      // Update budgeted categories
      const budgeted = processedCategories.filter(cat => cat.total > 0);
      setBudgetedCategories(budgeted);

      // Sort and set top/bottom categories
      const sortedBySpent = [...processedCategories].sort((a, b) => b.spent - a.spent);
      setTopCategories(sortedBySpent.filter(cat => cat.spent > 0).slice(0, 5));
      setBottomCategories(sortedBySpent.filter(cat => cat.spent > 0).slice(-5).reverse());

    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="bg-red-600 text-white p-4 rounded-lg shadow-lg flex items-center">
        <AlertCircle className="w-6 h-6 mr-2" />
        <span>{error}</span>
      </div>
    );
  }

  return (
    <div className="w-full max-w-lg mx-auto space-y-6">
      <div className="space-y-6">
        {/* Header Section */}
        <div className="border-b border-gray-800 pb-6">
          <h2 className="text-2xl font-bold text-white mb-4 text-center">Análise de Categorias</h2>
          <DateRangeSelector
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            onDateChange={loadData}
          />
        </div>
  
        {/* Conditional Rendering */}
        {categoryData.length > 0 ? (
          <div className="space-y-8 w-full">
            {/* Top/Bottom Categories Grid */}
            <div className="grid grid-cols-1 gap-8">
                  <TopBottomCategories 
                    title="Maiores Gastos por Categorias" 
                    categories={topCategories} 
                    icon={TrendingUp} 
                  />
                  <TopBottomCategories 
                    title="Menores Gastos por Categorias" 
                    categories={bottomCategories} 
                    icon={TrendingDown} 
                  />
                </div>
  
            {/* Budgeted Categories - Grid Layout */}
            <div className="mt-6">
              <BudgetedCategories budgetCategories={budgetedCategories} />
            </div>
  
            {/* Non-Budgeted Categories - Grid Layout */}
            <div className="mt-6">
              <NonBudgetedCategories
                transactions={transactions}
                budgetedCategories={categories}
              />
            </div>
          </div>
        ) : (
          <div className="text-white bg-gray-800 p-6 rounded-lg text-center">
            Não há dados disponíveis para o período selecionado.
          </div>
        )}
      </div>
    </div>
  );
};

// Keep all existing component definitions
const DateRangeSelector = ({ startDate, endDate, setStartDate, setEndDate, onDateChange }) => (
  <div className="flex justify-center text-2xl items-center mb-6">
    <div className="flex space-x-4">
      <CustomDateInput
        value={startDate}
        onChange={(date) => {
          setStartDate(date);
          onDateChange();
        }}
        className="bg-white text-black p-2 rounded"
      />
      <CustomDateInput
        value={endDate}
        onChange={(date) => {
          setEndDate(date);
          onDateChange();
        }}
        className="bg-white text-black p-2 rounded"
      />
    </div>
  </div>
);


const TopBottomCategories = ({ title, categories, icon: Icon }) => (
  <div className="bg-gray-800 p-4 rounded-lg shadow">
    <h3 className="text-xl font-semibold mb-4 text-white flex items-center">
      <Icon className="w-6 h-6 mr-2" />
      {title}
    </h3>
    <ul className="space-y-3">
      {categories.map((category, index) => (
        <li key={`${category.name}-${index}`} className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="w-8 h-8 mr-3 bg-white rounded-full ">
              {getCategoryIcon(category.name)}
            </div>
            <span className="text-white">{category.name}</span>
          </div>
          <div className="flex items-center">
            <span className="text-sm font-medium text-gray-400 mr-2">
              {formatCurrency(category.spent)}
            </span>
            {category.spent > category.total ? (
              <ArrowUpRight className="w-4 h-4 text-red-500" />
            ) : (
              <ArrowDownRight className="w-4 h-4 text-green-500" />
            )}
          </div>
        </li>
      ))}
    </ul>
  </div>
);

const BudgetedCategories = ({ budgetCategories }) => {
  const sortedCategories = budgetCategories.sort((a, b) => (b.spent || 0) - (a.spent || 0));

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full">
      <h3 className="text-xl font-bold mb-6 text-white">Categorias Orçadas</h3>
      <div className="space-y-4">
        {sortedCategories.map((category, index) => {
          const total = category.total || 0;
          const spent = category.spent || 0;
          const remaining = category.remaining || 0;
          const percentUsed = total > 0 ? Math.round((spent / total) * 100) : 0;
          const barWidth = Math.min(percentUsed, 100);

          return (
            <div key={`${category.name}-${index}`} className="bg-gray-700 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                  <div className="w-8 h-8 mr-3 bg-white rounded-full">
                    {getCategoryIcon(category.name)}
                  </div>
                  <span className="text-l font-semibold text-white">{category.name}</span>
                </div>
                <span className="flex text-sm font-medium text-gray-300 ml-4">
                  {formatCurrency(remaining)} restante
                </span>
              </div>
              <div className="w-full bg-gray-600 rounded-full h-4 mb-2 overflow-hidden">
                <div
                  className={`h-4 ${getHeatmapColorBars(percentUsed)}`}
                  style={{ width: `${barWidth}%` }}
                ></div>
              </div>
              <div className="flex justify-between text-sm text-gray-100">
                <span>Gasto: {formatCurrency(spent)}</span>
                <span>Total: {formatCurrency(total)}</span>
                <span>{percentUsed}% usado</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const NonBudgetedCategories = ({ transactions, budgetedCategories }) => {
  const budgetedCategoryNames = new Set(budgetedCategories.map(cat => 
    cat.name.toLowerCase()
  ));
  
  const expensesByCategory = transactions.reduce((acc, transaction) => {
    if (!transaction.category || transaction.type !== 'expense') return acc;
    const categoryKey = transaction.category.toLowerCase();
    if (budgetedCategoryNames.has(categoryKey)) return acc;

    if (!acc[categoryKey]) {
      acc[categoryKey] = {
        name: transaction.category,
        spent: 0
      };
    }
    acc[categoryKey].spent += Math.abs(transaction.amount);
    return acc;
  }, {});

  // Sort by spent amount in descending order
  const sortedCategories = Object.values(expensesByCategory)
    .sort((a, b) => b.spent - a.spent);

  if (sortedCategories.length === 0) return null;

  const totalSpent = sortedCategories.reduce((sum, cat) => sum + cat.spent, 0);

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold mb-6 text-white">Categorias Sem Orçamento</h3>
      <div className="space-y-4">
        {sortedCategories.map((category, index) => {
          const spent = category.spent || 0;
          // Calculate the percentage of total non-budgeted spending
          const percentOfTotal = Math.round((spent / totalSpent) * 100);
          const barWidth = percentOfTotal;

          return (
            <div key={`${category.name}-${index}`} className="bg-gray-700 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                  <div className="w-8 h-8 mr-3 bg-white rounded-full">
                    {getCategoryIcon(category.name)}
                  </div>
                  <span className="text-l font-semibold text-white">{category.name}</span>
                </div>
                <span className="flex text-sm font-medium text-gray-300 ml-4">
                  {formatCurrency(spent)}
                </span>
              </div>
              <div className="w-full bg-gray-600 rounded-full h-4 mb-2 overflow-hidden">
                <div
                  className="h-4 bg-red-500"
                  style={{ width: `${barWidth}%` }}
                ></div>
              </div>
              <div className="flex justify-between text-sm text-gray-100">
                <span>Gasto: {formatCurrency(spent)}</span>
                <span>{percentOfTotal}% do total não orçado</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Categories;